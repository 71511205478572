function isString(value: any): value is string {
	return typeof value === 'string';
}

function isStringNullOrEmpty(value: string | number | null | undefined) {
	return !value || value === '' || value === null || value === 'null' || value === 'undefined';
}

function capitalize(text: string) {
	if (isStringNullOrEmpty(text)) return text;

	let separators = text.match(/[\s/]+/g) || [];
	let words = text.split(/[\s/]+/);

	let capitalizedWords = words.map((word, index) => {
		return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1) + (separators[index] || '');
	});

	return capitalizedWords.join('');
}

function createStringKey(...args: any[]) {
	return args.join('_');
}

export function stringToNumber(value: any): number {
	if (isStringNullOrEmpty(value) || typeof value == 'number') return value;
	return parseFloat(value?.replace(/[^0-9.,]/g, ''));
}

export { isString, isStringNullOrEmpty, capitalize, createStringKey };
