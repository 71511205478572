import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';
import { IsRepresentativeGuard } from '@lib-core/guards';
import {
	ACCOUNT_PATH,
	ACCOUNT_ROUTE_NAMES,
	AccountInfoComponent,
	AccountEbooksComponent,
	AccountOrdersComponent,
	AccountTitlesComponent,
	AccountInvoicesComponent,
	AccountDownloadsComponent,
	AccountCustomersComponent,
} from '@lib-shared/modules/account';

import { AccountWrapperComponent } from '@proj-b2b/app/modules/account/account-wrapper.component';
import { AccountCreditLimitComponent } from './account-credit/account-credit-limit.component';

export const ACCOUNT_ROUTE_LIST: Routes = [
	{
		path: ACCOUNT_PATH,
		component: AccountWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: ACCOUNT_ROUTE_NAMES.info.path,
			},
			{
				path: ACCOUNT_ROUTE_NAMES.info.path,
				title: ACCOUNT_ROUTE_NAMES.info.title,
				component: AccountInfoComponent,
				canActivate: [AuthGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.creditLimit.path,
				title: ACCOUNT_ROUTE_NAMES.creditLimit.title,
				component: AccountCreditLimitComponent,
				canActivate: [AuthGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.customers.path,
				title: ACCOUNT_ROUTE_NAMES.customers.title,
				component: AccountCustomersComponent,
				canActivate: [AuthGuard, IsRepresentativeGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.orders.path,
				title: ACCOUNT_ROUTE_NAMES.orders.title,
				component: AccountOrdersComponent,
				canActivate: [AuthGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.ebooks.path,
				title: ACCOUNT_ROUTE_NAMES.ebooks.title,
				component: AccountEbooksComponent,
				canActivate: [AuthGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.invoices.path,
				title: ACCOUNT_ROUTE_NAMES.invoices.title,
				component: AccountInvoicesComponent,
				canActivate: [AuthGuard],
			},
			// {
			// 	path: ACCOUNT_ROUTE_NAMES.titles.path,
			// 	title: ACCOUNT_ROUTE_NAMES.titles.title,
			// 	component: AccountTitlesComponent,
			// 	canActivate: [AuthGuard],
			// },
			// {
			// 	path: ACCOUNT_ROUTE_NAMES.downloads.path,
			// 	title: ACCOUNT_ROUTE_NAMES.downloads.title,
			// 	component: AccountDownloadsComponent,
			// 	canActivate: [AuthGuard],
			// },
		],
	},
];
