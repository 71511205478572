<shared-page-title />
<div core-group-box class="col-24 mb-4">
	<ng-template #header>Limite de Crédito</ng-template>

	<ng-template #body *ngIf="creditInformation">
		<core-label-value label="Limite total de crédito" [value]="creditInformation.total | brl" [colon]="true" [singleLine]="true" />
		<core-label-value label="Observações" [value]="creditInformation.observation" [colon]="true" [singleLine]="true" />

		<ngb-progressbar-stacked class="col-24 col-md-16 mt-2">
			<ngb-progressbar type="danger" [striped]="true" [value]="progressValues.used" aria-label="Limite utilizado" />
			<ngb-progressbar type="success" [striped]="true" [value]="progressValues.available" aria-label="Limite disponível" />
		</ngb-progressbar-stacked>
		<div class="d-flex flex-column flex-sm-row col-24 col-md-16 mt-2">
			<core-label-value class="col-24 col-sm-12" label="Utilizado" [value]="creditInformation.used | brl" [colon]="true" [singleLine]="true" />
			<core-label-value
				class="col-24 col-sm-12 text-success d-flex justify-content-sm-end"
				label="Disponível"
				[value]="creditInformation.available | brl"
				[colon]="true"
				[singleLine]="true" />
		</div>
	</ng-template>

	<ng-template #body *ngIf="errorMessage">
		<core-label-value label="Limite de Crédito" [value]="errorMessage" [colon]="true" [singleLine]="true" />
	</ng-template>
</div>
