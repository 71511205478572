<div class="gallery-card">
	<div class="position-relative">
		<button (click)="previousImage()" class="position-absolute h-100 w-25 top-50 start-0 translate-middle-y bg-transparent border-0 opacity-50">
			{{ leftArrow }}
		</button>
		<app-product-image
			[height]="180"
			[imageList]="imageList"
			[url]="getImageUrl()"
			(click)="onAddProductToCard()"
			(keydown.enter)="onAddProductToCard()"
			(mouseenter)="startAutoNextImage()"
			(mouseleave)="stopAutoNextImage()" />
		<button (click)="nextImage()" class="position-absolute h-100 w-25 top-50 end-0 translate-middle-y bg-transparent border-0 opacity-50">
			{{ rightArrow }}
		</button>
	</div>

	<div class="text-center my-2">
		<span class="d-block fw-medium mb-1 text-truncate">{{ product.description }}</span>
		<span class="d-block fw-medium mb-1">{{ product.code }}</span>
		<div class="d-flex justify-content-center align-items-center" [style.height]="'30px'">
			<div [style.width]="'35px'">
				<button class="border-0 p-1 bg-transparent opacity-50" *ngIf="startColorIndex > 0" (click)="previousColorSection()">{{ leftArrow }}</button>
			</div>
			<div class="gallery-card__colors">
				<ng-container *ngFor="let color of currentColorSection">
					<app-color-sample
						[rgb]="color.rgb"
						[sampleUrl]="color.sampleUrl"
						(colorSelected)="onColorChange(color)"
						placement="bottom"
						ngbTooltip="{{ color.description | capitalize }}"></app-color-sample>
				</ng-container>
			</div>
			<div [style.width]="'35px'">
				<button class="border-0 p-1 bg-transparent opacity-50" *ngIf="startColorIndex + 5 < filteredColors.length" (click)="nextColorSection()">
					{{ rightArrow }}
				</button>
			</div>
		</div>
		<span class="d-block mb-1"
			>A partir de <span class="text-success fw-semibold">{{ product.minPrice | brl }}</span></span
		>
		<span class="d-block mb-2">Estoque: {{ product.totalStock }} </span>
	</div>
	<div class="text-center">
		<button type="submit" class="btn w-100 me-2" [class]="buttonClass" (click)="onAddProductToCard()">
			<i [class]="buttonIcon"></i>
			{{ buttonLabel }}
		</button>
	</div>
</div>
