import { CommonModule } from '@angular/common';
import {
	NgbDateAdapter,
	NgbDropdownModule,
	NgbDatepickerI18n,
	NgbAccordionModule,
	NgbDatepickerModule,
	NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, effect } from '@angular/core';
import { FormGroup, Validators, FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { MULTI_SELECT_DEFAULT_CONFIG } from '@lib-core/constants';

import { formatDate } from '@lib-core/helpers';
import { DataTablesModule } from 'angular-datatables';
import { InvoiceRequestDto } from '@lib-core/dtos';
import { PageTitleComponent } from '@lib-shared/components';
import { CustomerService, InvoiceService, SalesOrderService, ToastService } from '@lib-core/services';
import { CustomerStore, RepresentativeStore } from '@lib-core/stores';
import { CustomerModel, NfeModel, RepresentativeModel, SalesOrderModel } from '@lib-core/models';
import { CpfCnpjPipe, CurrencyBrlPipe, DateShortPipe } from '@lib-core/pipes';
import { getDataTableOptions, getDataTableOrderByIndex } from '@lib-core/config';
import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '@lib-core/providers';
import { ImagesService } from '@lib-core/services/images.service';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
	imports: [
		CpfCnpjPipe,
		FormsModule,
		CommonModule,
		DateShortPipe,
		CurrencyBrlPipe,
		DataTablesModule,
		NgbDropdownModule,
		NgbAccordionModule,
		ReactiveFormsModule,
		PageTitleComponent,
		NgbDatepickerModule,
		NgSelectModule,
	],
	providers: [
		I18n,
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
	standalone: true,
	selector: 'shared-account-invoices',
	templateUrl: './account-invoices.component.html',
})
export class AccountInvoicesComponent implements OnInit {
	dtOptions = { ...getDataTableOptions(), ...getDataTableOrderByIndex(1) };
	submitted = false;
	filterCollapsed = false;

	filterForm!: FormGroup;
	invoiceList: NfeModel[];
	customerList: CustomerModel[];
	currentRepresentative: RepresentativeModel;
	customerDropdownSettings = { ...MULTI_SELECT_DEFAULT_CONFIG };
	orderDetail = SalesOrderModel;
	orderProductsImages: any[];

	constructor(
		private fb: FormBuilder,
		private customerStore: CustomerStore,
		private invoiceService: InvoiceService,
		private customerService: CustomerService,
		private representativeStore: RepresentativeStore,
		private salesOrderService: SalesOrderService,
		private imagesService: ImagesService,
		private toastService: ToastService,
	) {
		effect(() => {
			this.currentRepresentative = this.representativeStore.get();
			this.queryCustomers();
			this.onFilter();
		});
	}

	ngOnInit() {
		this.registerForm();
		this.loadDates();
	}

	get f() {
		return this.filterForm.controls;
	}

	registerForm() {
		const customer = this.customerStore.get() ? this.customerStore.get().code : '';
		this.filterForm = this.fb.group({
			customers: [customer],
			emittedTo: ['', Validators.required],
			emittedFrom: ['', Validators.required],
		});
	}

	loadDates() {
		const today = new Date();
		const monthAgo = new Date(today);
		monthAgo.setMonth(today.getMonth() - 6);

		this.filterForm.patchValue({
			emittedFrom: formatDate(monthAgo),
			emittedTo: formatDate(today),
		});
	}

	onFilter() {
		this.submitted = true;
		this.invoiceList = [];

		if (this.filterForm.invalid) {
			return;
		}

		this.queryInvoices();
	}

	onReset() {
		this.submitted = false;
		this.filterForm.reset();
	}

	onRestore() {
		this.submitted = false;
		this.queryCustomers();
		this.loadDates();
	}

	isRepresentative() {
		return this.representativeStore.isValid();
	}

	queryCustomers() {
		if (this.isRepresentative()) {
			this.queryCustomersFromRepresentative();
			return;
		}

		this.queryCurrentCustomer();
	}

	queryCurrentCustomer() {
		const customer = this.customerStore.get();
		this.customerList = [customer];
		this.patchCustomer(customer);
	}

	queryCustomersFromRepresentative() {
		if (!this.currentRepresentative) {
			return;
		}

		const customer = this.customerStore.get();

		if (this.customerList?.length > 0) {
			this.patchCustomer(customer);
			return;
		}

		this.customerService.listCustomersByRepresentative(this.currentRepresentative.code).subscribe(response => {
			this.customerList = response.data.filter(customer => customer.code !== null);
			this.patchCustomer(customer);
		});
	}

	patchCustomer(customer: CustomerModel) {
		if (customer) {
			this.filterForm.patchValue({ customers: [customer.code || customer] });
		}
	}

	queryInvoices() {
		const filter = this.createFilter();
		this.invoiceList = null;

		this.invoiceService.queryInvoices(filter).subscribe({
			next: response => {
				if (response.data && response.data.length > 0) {
					this.invoiceList = response.data;
					return;
				}
				this.invoiceList = [];
			},
			error: response => {
				var error = response.message;
				this.showError(error);
			},
		});
	}

	showError(error: string): void {
		this.toastService.danger(error);
	}

	createFilter(): InvoiceRequestDto {
		const { emittedFrom, emittedTo, customers } = this.filterForm.value;
		const filter = new InvoiceRequestDto();
		filter.clientes = Array.isArray(customers) ? customers.map((customer: CustomerModel) => customer.code || customer) : customers?.length ? [customers] : [];

		filter.emissaoDe = emittedFrom;
		filter.emissaoAte = emittedTo;
		filter.representantes = this.currentRepresentative?.code ? [this.currentRepresentative.code] : [];

		return filter;
	}

	get hasInvoices() {
		return this.invoiceList;
	}

	onDownloadXml(invoice: NfeModel) {
		this.invoiceService.downloadXml(invoice.chaveNFE);
	}

	onDownloadDanfe(invoice: NfeModel) {
		this.invoiceService.downloadDanfe(invoice.chaveNFE);
	}

	onDownloadImages(salesOrder: string) {
		this.salesOrderService.detailOrderItens(salesOrder, true, true).subscribe({
			next: response => {
				if (response.success) {
					this.orderDetail = response.data;
					this.orderProductsImages = response.data.productsList;
					this.imagesService.downloadImagesZip(this.orderProductsImages, salesOrder, true, true);
					return;
				}
				this.showQueryErrorToast(response.message);
			},
			error: error => {
				this.showQueryErrorToast('Erro ao baixar imagens: ' + error.message);
			},
		});
	}

	showQueryErrorToast(message: string) {
		this.toastService.danger(`Erro ao processar pedido: ${message}`);
	}
}
