import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { isStringNullOrEmpty } from '@lib-core/helpers';

@Component({
	selector: 'core-label-value, [core-label-value]',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './label-value.component.html',
})
export class LabelValueComponent {
	@Input()
	label: string | number;

	@Input()
	value: string;

	@Input()
	colon: boolean = false;

	@Input()
	singleLine: boolean = false;

	@Input()
	useInput: boolean = false;

	@Input()
	classList: string = '';

	@Input()
	labelClassList: string = '';

	@Input()
	valueClassList: string = '';

	@Input()
	showEmpty: boolean = false;

	get showLabel() {
		return !isStringNullOrEmpty(this.label);
	}

	get showValue() {
		return !isStringNullOrEmpty(this.value) || this.showEmpty;
	}
}
