<div ngbAccordion class="mb-4">
	<div ngbAccordionItem [collapsed]="true">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>Detalhes do produto</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					<span class="form-label fw-semibold">Categoria</span>
					<p>{{ product.categoryDescription }}</p>
					<span class="form-label fw-semibold">Segmento</span>
					<p>{{ product.segmentDescription }}</p>
					<span class="form-label fw-semibold">Espécie</span>
					<p>{{ product.specieDescription }}</p>
					<span class="form-label fw-semibold">Gênero</span>
					<p>{{ product.genderDescription }}</p>
					<span class="form-label fw-semibold">Grupo produto</span>
					<p>{{ product.productGroupDescription }}</p>
					<span class="form-label fw-semibold">Destino</span>
					<p>{{ product.recipientDescription }}</p>
				</ng-template>
			</div>
		</div>
	</div>
</div>

<div ngbAccordion class="mb-4">
	<div ngbAccordionItem [collapsed]="true">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>Tabela de medidas</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					<div class="overflow-auto">
						<img src="{{ getMeasureTableImage() }}" alt="" />
						<div class="p-2">
							<p *ngIf="getMeasureTableImage() == null"><strong>Erro: </strong> Nenhuma tabela de medida encontrada para o produto especificado</p>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
