import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { NgbAccordionModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { PageTitleComponent } from '@lib-shared/components';
import { GroupBoxComponent, LabelValueComponent } from '@lib-core/components';
import { CurrencyBrlPipe } from '@lib-core/pipes';
import { CreditLimitModel } from '@lib-core/models/credit-limit.model';
import { CustomerService, ToastService } from '@lib-core/services';
import { CustomerStore } from '@lib-core/stores';

@Component({
	standalone: true,
	selector: 'b2b-account-credit-limit',
	imports: [CommonModule, NgbAccordionModule, NgbProgressbarModule, PageTitleComponent, LabelValueComponent, GroupBoxComponent, CurrencyBrlPipe],
	templateUrl: './account-credit-limit.component.html',
})
export class AccountCreditLimitComponent {
	creditInformation: CreditLimitModel | null = null;

	constructor(private readonly toastService: ToastService, private readonly customerService: CustomerService, private readonly customerStore: CustomerStore) {
		effect(() => {
			if (this.customerStore.isValid()) {
				this.fetchCreditLimit();
			}
		});
	}

	get isValid(): boolean {
		return this.creditInformation?.total > 0;
	}

	get errorMessage(): string {
		if (!this.customerStore.isValid()) {
			return 'Por favor, selecione um cliente para verificar seu limite';
		}

		if (this.isValid) {
			return 'Erro ao obter informações de crédito, por favor tente novamente.';
		}

		return '';
	}

	get progressValues() {
		if (!this.isValid) {
			return { used: 0, available: 0 };
		}

		const { total, used, available } = this.creditInformation;
		return {
			used: (used / total) * 100,
			available: (available / total) * 100,
		};
	}

	private fetchCreditLimit(): void {
		this.customerService.getCreditLimit().subscribe({
			next: response => {
				if (response.success) {
					this.creditInformation = response.data;
					return;
				}
				this.toastService.danger(response.message);
			},
			error: error => {
				const errorMessage = error.message;
				this.toastService.danger(errorMessage);
			},
		});
	}
}
