import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { ElementRef, Injectable } from '@angular/core';

import { CustomerStore } from '@lib-core/stores';
import { CustomerResource } from '@lib-core/resources';
import { ApiResponseModel, CustomerModel } from '@lib-core/models';
import { CreditLimitModel } from '@lib-core/models/credit-limit.model';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	constructor(private readonly customerResource: CustomerResource, private readonly customerStore: CustomerStore, private readonly modalService: NgbModal) {}

	listCustomersByRepresentative(representativeCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.customerResource.listCustomersByRepresentative(representativeCode);
	}

	getCustomerByCode(customerCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.customerResource.GetCustomerByCode(customerCode);
	}

	getCurrentCustomer() {
		return of(this.customerStore.get());
	}

	openCustomerSelectModal(modalInstance: ElementRef): Promise<void> {
		return new Promise<void>(resolve => {
			this.modalService.open(modalInstance, { size: 'xl', backdrop: 'static', keyboard: false }).result.then(
				() => resolve(),
				() => resolve(),
			);
		});
	}

	getCreditLimit(): Observable<ApiResponseModel<CreditLimitModel>> {
		if (!this.customerStore.isValid()) {
			return of<ApiResponseModel<CreditLimitModel>>({
				data: null,
				success: false,
				message: 'Por favor, selecione um cliente para verificar seu limite',
			});
		}
		return this.customerResource.getCreditLimit(this.customerStore.get().code);
	}
}
