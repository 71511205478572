export enum SalesReturnFieldIdType {
	Material = 2,
	Invoice = 1,
	Size = 3,
	Color = 4,
	Quantity = 5,
	Value = 6,
	Failure = 7,
	Attachment1 = 8,
	Attachment2 = 10,
	Attachment3 = 11,
	Notes = 12,
	TransportationType = 16,
	TrackingCode = 17,
	InvoiceNumber = 26,
	InvoiceSeries = 31,
	AttachmentProofOfDiscard = 33,
	InvoiceIssueDate = 34,
	InvoiceAttachment = 30,
	Requester = 42,
}

export enum SalesReturnFieldType {
	Text = 1,
	Number = 2,
	Date = 3,
	TextArea = 4,
	RichText = 5,
	ComboBox = 6,
	EditDisplay = 7,
	Checkbox = 8,
	EditDisplayTable = 9,
	DateDiff = 10,
	Attachment = 11,
	QuantityWithValidation = 12,
}

export const SalesReturnFieldNameType = {
	[SalesReturnFieldIdType.Size]: '#CDTAMANHO#',
	[SalesReturnFieldIdType.Color]: '#CDCOR#',
	[SalesReturnFieldIdType.Invoice]: '#CDNOTA#',
	[SalesReturnFieldIdType.Failure]: '#CDMOTIVOFALHA#',
	[SalesReturnFieldIdType.Material]: '#CDMATERIAL#',
	[SalesReturnFieldIdType.Quantity]: '#CDQUANTIDADE#',
	[SalesReturnFieldIdType.Value]: '#CDVALOR#',
};
